@font-face {
  font-family: Outfit-regular;
  src: url(../../assets/font/Outfit-Thin.ttf);
}

@font-face {
  font-family: BEYNO;
  src: url(../../assets/font/BEYNO.ttf);
}

@font-face {
  font-family: Mohave-bold;
  src: url(../../assets/font/Mohave-Light.ttf);
}

.sub_nav {
  display: flex;
  height: 5rem;
  position: fixed;
  top: 0;
  z-index: 1000;

  .navbar {
    background: #111111;
    padding: 1em 5%;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Outfit-regular;
    display: flex;
    justify-content: space-between;
    height: 5rem;
    width: 100vw;
    z-index: 5;

    .zairzest-beyno-text {
      font-family: "BEYNO";
      letter-spacing: 9px;
      font-size: 35.2px;
    }

    .fourO-text {
      // color: ;
      font-family: "Mohave-Bold";
      letter-spacing: 9px;
      font-size: 35.2px;
      color: #38ccff;
    }

    a {
      text-decoration: none;
      color: #ffffff;
    }

    h4 {
      text-decoration: none;
      color: #ffffff;
    }

    h4:hover {
      cursor: pointer;
    }

    .navbar__left {
      display: flex;
      align-items: center;
      background: transparent;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navbar__right {
      height: 5rem;
      display: flex;
      align-items: center;
      background: transparent;
      overflow-y: hidden;

      .navbar__text {
        text-align: center;
        padding: 2em;
        padding-top: 2em;
        padding-bottom: 2em;
        background: transparent;
      }

      .navbar__text:hover {
        color: rgb(34, 116, 232);
      }

      .navbar__menu {
        color: #ffffff;
        font-size: 1.5em;
      }
    }

    .navbar__menu:hover {
      cursor: pointer;
    }
  }

  .navbar_content {
    a {
      text-decoration: none;
      color: #ffffff;
    }

    h4 {
      text-decoration: none;
      color: #ffffff;
    }

    h4:hover {
      cursor: pointer;
    }

    position: fixed;
    z-index: 6;
    min-height: 100vh;
    overflow-y: scroll;
    height: 100%;
    width: 30vw;
    background-color: #111111;
    color: #ffffff;
    animation: 0.4s ease-out 0s 1 slideInRight;
    right: 0;
    padding-bottom: 2rem;

    .nav-button-content {
      cursor: pointer;
    }

    .nav-content-container {
      background: transparent;
    }

    .close_icon {
      text-align: right;
      margin: 2rem;
      margin-bottom: 5px;
      cursor: pointer;

      .navbar__menu {
        display: inline;
        font-size: 1.5em;
      }
    }

    .nav-button-content {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 3rem;
    }

    @keyframes slideInRight {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes slideInLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(100%);
      }
    }
  }

  .navbar_content_close {
    a {
      text-decoration: none;
      color: #ffffff;
    }

    h4 {
      color: #ffffff;
    }

    h4:hover {
      cursor: pointer;
    }

    position: fixed;
    z-index: 2;
    min-height: 100vh;
    overflow-y: scroll;
    height: 100%;
    width: 30vw;
    background-color: rgba(15, 21, 26, 1);
    color: #ffffff;
    animation: 0.4s ease-out 0s 1 slideInLeft;
    right: 0;
    padding-bottom: 2rem;

    .nav-button-content {
      cursor: pointer;
    }

    .close_icon {
      text-align: end;
      margin: 2rem;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .nav-button-content {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 3rem;
    }

    .navbar-container__icons {
      color: white;
      cursor: pointer;
    }

    .navbar-container__social_icons {
      background-color: #bcd7ff;
      border-radius: 24px;
      display: flex;
      padding: 12px;
    }

    @keyframes slideInRight {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes slideInLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(100%);
      }
    }
  }

  @media (max-width: 1400px) {
    .navbar_content {
      width: 40vw;
    }

    .navbar_content_close {
      width: 40vw;
    }
  }

  @media (min-width: 900px) {
    .navbar__right {
      .navbar__menu {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    .navbar_content {
      width: 60vw;
      overflow-y: scroll;

      .navbar_content_close {
        width: 60vw;
        overflow-y: scroll;
      }
    }

    .navbar__right {
      .navbar__text {
        display: none;
      }
    }

    .navbar {
      .zairzest-beyno-text,
      .fourO-text {
        font-size: 27px;
      }
    }
  }

  @media (max-width: 700px) {
    .navbar_content {
      width: 70vw;
      overflow-y: scroll;

      .nav-button-content {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
      }
    }

    .navbar {
      .zairzest-beyno-text,
      .fourO-text {
        font-size: 24px;
      }
    }

    .navbar_content_close {
      width: 70vw;
      overflow-y: scroll;

      .nav-button-content {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 500px) {
    .navbar_content {
      width: 100vw;
      background-color: #111111;

      .nav-button-content {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
      }
    }

    .navbar {
      .zairzest-beyno-text,
      .fourO-text {
        font-size: 21px;
      }
    }

    .navbar_content {
      width: 100vw;
      background-color: #111111;

      .nav-button-content {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
      }
    }
  }
}
.sub_nav{
  .zairza {
    width: 243px;
    height: auto;
    position: fixed;
    bottom: 0;
  
    margin-left: 2rem;
    margin-bottom: 2.5rem;
  }
  
  @media(max-witdh: 410px) {
    .zairza {
      width: 220px;
    }
  }
}
#navbarprofile {
  color: #38ccff;
  // font-size:;
  font-weight: 700;
}
#navbarprofile:hover {
  color: white;
  text-shadow: rgba(0, 242, 238, 1) 0px 0px 75px;
}
