.event-container {
    padding: 2rem 10%;
    background-color: #111111;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 100px;

    .music {
        top: 55%;
        left: 90%;
    }

    .mname {
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        margin-top: 50px;
        color: #fff;
    }

    .card-contain {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 50px;
        justify-items: center;
        align-items: center;
        width: 100%;
    }

    .null_text {
        color: gray;
        font-weight: bold;
    }

    .music {
        position: absolute;
        top: 94%;
        left: 94%;
    }

    @media (max-width: 1350px) {
        .mname {
            margin-left: 0;
            text-align: center;
            font-size: 50px;
        }

        .music {
            top: 97%;
            left: 85%;
        }

        .card-contain {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
        }
    }

    @media (max-width: 890px) {
        .music {
            left: 92%;
        }
    }

    @media (max-width: 760px) {
        .music {
            top: 98%;
            left: 91%;
        }
    }

    @media (max-width: 860px) {
        .card-contain {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
        }

        .mname {
            margin-left: 0;
            text-align: center;
            font-size: 45px;
        }

        .music {
            top: 98.5%;
            left: 90%;
        }

    }

    @media (min-width: 1760px) {
        .card-contain {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
        }
    }

    @media (min-width: 2150px) {
        .card-contain {
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: auto;
        }
    }
}

@media screen and (max-width:600px) {
    .event-container {
        padding: 4rem 10%;
        padding-bottom: 100px;
    }

    .music {
        top: 98.5%;
    }

}