
*{
    margin: 0;
    padding: 0;
}

.login-left-container{
    background-image: url(../../assets/images/auth_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    padding:0rem 5rem;
}
.loginleft-text{
    
    font-weight: bold;
    font-size:30px;
    
}

.loginleft-para{
    width:370px;
}

.login-left-colors{
    display: flex;
    align-items: center;
    gap:1rem;
}
@media(max-width: 1096px){
    .login-left-container{
        height: 115vh;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media(max-width:1020px){
    .login-left-container{
        width: 275px;
    }
}

@media(max-width:871px){
    .login-left-container{
        width: 200px;
    }
    .loginleft-text{
        width:300px;
        font-size:25px;
    }
    
    .loginleft-para{
        width:300px;
    }
    
}
@media(max-width: 799px){
    .login-left-container{
        height: 136vh;
    }
}
