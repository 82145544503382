.card-container {
  width: 360px;
  height: 550px;
  perspective: 1000px; // Creates the 3D space for the flip

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; // Ensures both sides are in the 3D space
  transition: transform 0.6s ease; // Smooth flipping effect

  &.flipped {
    transform: rotateY(180deg); // Flip the card when this class is added
  }

  .card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; // Hide the back when flipping

    &.front {
      background-color: #1f1f1f;
      border-radius: 10px;
    }

    &.back {
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.9276960784313726) 4%, rgba(5,100,170,1) 100%);
      border-radius: 10px;
      color: white;
      font-size: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotateY(180deg); // The back face is already flipped, ready to show on card flip
    }
  }
}

.imgsec img {
  margin: 8px;
  width: 344px;
  height: 386px;
}

.nameenroll {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  margin: 0 8px 8px 8px;
  align-items: center;
  color: #fff;

  .ename {
    font-weight: 600;
    font-size: 18px;
  }
}

.dtp {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  margin: 0 8px 8px 8px;

  .edate {
    .etitle {
      color: #c4c4c4;
      font-weight: 400;
      font-size: 12px;
    }

    .dtime {
      color: #fff;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

.overlay-content {
  text-align: center;
  padding: 20px;

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .close-btn {
    padding: 10px 20px;
    background-color: #38ccff;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
    font-weight: 600;

    &:hover {
      opacity: 0.9;
    }
  }
}
}

@media screen and (max-width:769px) {
  .card-container {
    width: 300px;
    height: 480px;
  
  .imgsec img {
    margin: 8px;
    width: 284px;
    height: 326px;
  }
}
}
