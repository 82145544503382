@font-face{
  font-family: Outfit;
    src: url(./assets/font/Outfit-Regular.ttf);
}
body {
  margin: 0;
  font-family: Outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
