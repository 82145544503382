$gradient-start: #004157;
$gradient-end: #111111;
$highlight-color: #38CCFF;

.profile_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; 
  background: url("../../assets/images/profilebg.png") no-repeat center center;
  background-size: cover;
  background-color: black;
  height: 990px;
  margin-top: 80px;
  position: relative;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
}

.profile-box {
  background: linear-gradient(180deg, $gradient-start 0%, $gradient-end 100%);
  width: 360px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  text-align: center;
  
  &.qr-code-box {
    .qr-code-image {
      width: 240px;
      height: 270px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      margin-bottom: 20px;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    p {
      color: $highlight-color;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.profilesubitem1 {
  border-radius: 12px;
  background: white;
  height: 240px;
  width: 240px;
  margin-bottom: 20px;
}

.profilesubitem2 {
  h1 {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
  }

  span {
    color: $highlight-color;
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .profile-box {
    width: 310px;
    height: 400px;
    margin-bottom: 20px;

    .profilesubitem1,
    .qr-code-image {
      height: 200px;
      width: 200px;
    }
  }

  .qr-code-box {
    width: 310px;
  }
}

@media screen and (max-width: 480px) {
  .profile-box {
    width: 280px;

    .profilesubitem1,
    .qr-code-image {
      height: 190px;
      width: 190px;
    }
  }

  .qr-code-box {
    width: 280px;
  }
}
