* {
    margin: 0;
    padding: 0;
}

.background {
    background-color: #000000;
}

.About-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #000000;
}


.About-us-content {
    display: flex;
    max-width: 1130.46px;
    flex-direction: column;
    align-items: start;
    word-break: keep-all;
    padding: 5rem;
    height: 100%;
    background-color: #000000;
}

.About-us-header {
    color: #ffffff;
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.About-us-content p {
    color: #c3c3c3;
    font-weight: bold;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.About-us-frame {
    color: #c3c3c3;
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-width: 1130.46px;

    img {
        width: 100%;
    }
}

.footerr {
    margin-top: 8vw;
}

@media (max-width: 768px) {
    .About-us-header {
        font-size: 2.5rem;
    }

    .About-us-frame {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);

        img {
            width: 100%;
        }
    }

    .footerr {
        margin-top: 7rem;
    }
}
.music{
    overflow: visible;
    position: absolute;
    top: 96%;
    left: 100%;
}
@media (max-width: 1248px) {
    .music{
      left: 95%;
      top: 96%;
    }
  }

@media (max-width: 939px) {
  .music{
    top: 97%;
    left: 92%;
  }
}
@media (max-width: 769px) {
  .music{
    top: 97%;
    left: 85%;
  }
}

@media (max-width: 680px) {
    .music{
        top: 97.5%;
        left: 85%;
      }

    .About-us-frame {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

}
@media (max-width: 600px) {

    .music{
        top: 97.5%;
        left: 85%;
      }

    .About-us-content {
        margin: 0;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    .About-us-frame {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 500px){
    .music{
        top: 97.5%;
        left: 81%;
      }
}